import React from 'react';
import {graphql} from 'gatsby';
import Amplify from 'aws-amplify';

import Layout from '../components/Layout';
import config from '../services/aws-exports';
import {Login} from '../components/Login';

Amplify.configure(config);

interface Props {
    data: {
        site: {
            siteMetadata: {
                title: string;
            };
        };
    };
}

const Index = ({ data }: Props) => {
     return (
        <Layout >
            <Login/>
        </Layout>
    );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
